import React, { useState } from 'react';
import RoomIcon from '@mui/icons-material/Room';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import { addBdchContact } from 'api-ws/bdchContact-ws';
import AHeader from '../components/AHeader';
import ContactSuccessDialog from './ContactSuccess';
import { useEffect } from 'react';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const messageSubmit = (e) => {
    e.preventDefault();
    const messageRequest = { name, email, message };
    addBdchContact(messageRequest)
      .then((res) => {
        console.log('Message sent successfully:', res);
        setName('');
        setEmail('');
        setMessage('');
        setShowSuccess(true);
      })
      .catch((err) => console.log('Error sending message:', err));
  };

  const contactData = [
    {
      name: 'Dr. D.B. Nandeeshwar',
      position: 'Principal',
      phone: '08192-220572'
    },
    {
      name: 'Dr. Ashok L.',
      position: 'Vice-Principal',
      phone: '08192-220575'
    },
    {
      name: 'Mrs. Sandhya Rani P.M.',
      position: 'Manager',
      phone: '08192-220573'
    }
  ];
  useEffect(() => {
    // Add necessary styles for animations
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(-10px); }
        to { opacity: 1; transform: translateY(0); }
      }
      
      @keyframes bounceIn {
        0% { transform: scale(0.8); opacity: 0; }
        70% { transform: scale(1.05); opacity: 1; }
        100% { transform: scale(1); opacity: 1; }
      }
      
      .animate-bounce-in {
        animation: bounceIn 0.5s ease-out forwards;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
  return (
    <div className="mt-[8vh] md:mt-[25vh]">
      <AHeader header="CONNECT WITH US" isScrolled={true} />
      {showSuccess && <ContactSuccessDialog setShowSuccess={setShowSuccess} />}
      <div className="container px-4 py-8 mx-auto">
        <div className="flex flex-wrap -mx-4">
          {/* Left Column */}
          <div className="w-full px-4 mb-8 md:w-1/3">
            {/* Address Card */}
            <div className="p-6 mb-6 bg-white shadow-lg rounded-xl">
              <h2 className="mb-6 text-xl font-ebgaramond">Address</h2>
              <ul className="space-y-6">
                <li className="flex items-start space-x-4">
                  <PersonIcon className="flex-shrink-0 mt-1 text-gray-600" />
                  <span className="font-lora">The Principal</span>
                </li>
                <li className="flex items-start space-x-4">
                  <RoomIcon className="flex-shrink-0 mt-1 text-gray-600" />
                  <div className="font-lora">
                    <p>Bapuji Dental College & Hospital</p>
                    <p>Post Box No.326, DAVANGERE – 577004</p>
                    <p>Karnataka, India</p>
                  </div>
                </li>
                <li className="flex items-start space-x-4">
                  <MailOutlineIcon className="flex-shrink-0 mt-1 text-gray-600" />
                  <span className="font-lora">principal@bapujidental.edu</span>
                </li>
              </ul>
            </div>

            {/* Contact Information Card */}
            <div className="p-6 bg-white shadow-lg rounded-xl">
              <h2 className="mb-6 text-xl font-ebgaramond">Contact Information</h2>
              <div className="space-y-6">
                {contactData.map((contact, index) => (
                  <div key={index} className="font-lora">
                    <p className="font-medium">{contact.name}</p>
                    <p className="text-gray-600">{contact.position}</p>
                    <p className="text-gray-600">{contact.phone}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="w-full px-4 md:w-2/3">
            <div className="p-6 bg-white shadow-lg rounded-xl">
              <h2 className="mb-6 text-xl font-ebgaramond">Write a Message</h2>
              <form onSubmit={messageSubmit} className="space-y-4">
                <div>
                  <input
                    type="text"
                    placeholder="Name"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#00B4AC] focus:border-transparent outline-none font-lora"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#00B4AC] focus:border-transparent outline-none font-lora"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <textarea
                    placeholder="Message"
                    rows="7"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#00B4AC] focus:border-transparent outline-none font-lora"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="px-6 py-2 bg-[#00B4AC] text-white rounded-lg font-lora hover:bg-[#009B94] transition-colors duration-300"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Map */}
        <div className="mt-8">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.5442066055552!2d75.91392931432863!3d14.453411184542869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba2571eb98f1fd%3A0x1e9b7635c879caac!2sBapuji+Dental+College+and+Hospital!5e0!3m2!1sen!2sin!4v1506588013103"
            title="Maps Location"
            className="w-full h-[350px] rounded-xl"
            frameBorder="0"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
