import React, { useEffect, useState } from 'react';
import WebFont from 'webfontloader';

function AHeader({ header, isScrolled = false }) {
  // const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check for mobile view on mount and when window resizes
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkMobile();

    // Handle scroll events
    // const handleScroll = () => {
    //   const scrollTop = window.scrollY;
    //   setIsScrolled(scrollTop > 0);
    // };

    // window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', checkMobile);

    return () => {
      // window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Playfair Display:700', 'Poppins:400,600']
      }
    });
  }, []);

  // Determine margin top based on both mobile and scroll states
  const getMarginTop = () => {
    if (isMobile) {
      return 'mt-[8vh]';
    } else {
      return isScrolled ? 'mt-[25vh]' : 'mt-[10vh]';
    }
  };

  return (
    <div className={`flex flex-col items-center justify-center text-center mb-8 ${getMarginTop()}`}>
      <h1 className="text-4xl font-bold tracking-wide text-gray-800 md:text-5xl font-ebgaramond">
        {header}
      </h1>

      <div className="relative flex items-center justify-center w-full">
        <div className="h-[2px] bg-gray-300 w-[20%]"></div>

        <div className="relative mx-4">
          <div className="w-8 h-8 rounded-full shadow-lg bg-gradient-to-r from-blue-500 to-teal-500 animate-pulse"></div>

          <div className="absolute inset-0 w-full h-full border-4 border-transparent border-opacity-50 rounded-full animate-ping bg-gradient-to-r from-blue-300 to-teal-300"></div>
        </div>

        <div className="h-[2px] bg-gray-300 w-[20%]"></div>
      </div>
    </div>
  );
}

export default AHeader;
