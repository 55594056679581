import React from 'react';
import principalData from '../administration/principalData.json';
import AHeader from '../components/AHeader';

const Director = () => {
  const { viceprincipal } = principalData;

  return (
    <div className="container mx-auto">
      <AHeader header={'MESSAGE FROM VICE PRINCIPAL'} isScrolled={true} />

      <div className="p-4">
        <div className="flex flex-col gap-6 md:flex-row">
          {/* Image and Title Section */}
          <div className="w-full md:w-1/3">
            {/* Image Container */}
            <div
              className="relative flex items-center justify-center p-1 rounded-xl 
              before:absolute before:inset-0 before:blur-lg 
              before:bg-gradient-to-r before:from-[#0352A1] before:via-[#0180A6] 
              before:to-[#00B4AC] before:-z-10 hover:scale-[1.02] 
              transition-transform duration-300 ease-in-out"
            >
              <img
                src={'/assets/principal/viceprincipal.webp'}
                alt="Vice Principal"
                className="w-full md:h-[70vh] object-cover md:object-contain rounded-xl 
                  border-4 border-white shadow-md hover:border-[#0180A6] 
                  hover:shadow-xl transition-all duration-300"
              />
            </div>

            {/* Name and Title Card */}
            <div
              className="mt-4 border-2 border-[#0352A1]/20 px-4 py-2 rounded-lg 
              bg-[#f0f8ff] hover:bg-[#0352A1]/5 transition-all duration-300 
              shadow-md hover:shadow-lg text-center"
            >
              <span
                className="text-2xl font-bold font-ebgaramond 
                bg-gradient-to-r from-[#0352A1] via-[#0180A6] to-[#0352A1] 
                text-transparent bg-clip-text"
              >
                Dr.Ashok.L
              </span>
              <p
                className="text-base font-ebgaramond mt-1 
                bg-gradient-to-r from-[#0352A1] via-[#0180A6] to-[#0352A1] 
                text-transparent bg-clip-text"
              >
                Vice Principal, Professor and HOD.
              </p>
            </div>
          </div>

          {/* Message Section */}
          <div className="w-full mt-6 md:w-2/3 md:mt-0">
            <div
              className="relative text-[#2C3E50] text-justify p-6 
              bg-[#f9f9f9] rounded-xl shadow-md hover:shadow-lg 
              transition-all duration-300"
            >
              <div
                className="absolute inset-0 bg-center bg-no-repeat pointer-events-none opacity-15"
                style={{
                  backgroundImage: "url('/assets/logo/logo.png')",
                  filter: 'grayscale(60%) contrast(1.4)',
                  backgroundSize: '60%'
                }}
              ></div>
              <div className="relative z-10 space-y-4 text-base md:text-lg font-lora">
                <strong className="text-lg md:text-xl text-[#112D58] block">
                  {viceprincipal?.message1}
                </strong>
                <p>{viceprincipal?.message2}</p>
                <p>{viceprincipal?.message3}</p>
                <p>{viceprincipal?.message4}</p>
                <p>{viceprincipal?.message5}</p>
                <p>{viceprincipal?.message6}</p>
                <p>{viceprincipal?.message7}</p>
                <p>{viceprincipal?.message8}</p>
                <div className="pt-4">
                  <p>Warm Regards,</p>
                  <p>Dr.Ashok.L</p>
                  <p>Vice Principal, Professor and HOD.</p>
                  <p>Department of oral medicine and radiology</p>
                  <p>Bapuji dental college and Hospital</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Director;
