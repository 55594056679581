import {
  Button,
  Card,
  Container,
  Grid,
  styled,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import AHeader from '../components/AHeader';
import colorConfig2 from 'app/color/colorConfig2';
import bds from './bds.pdf';
import mds from './mds.pdf';
import AnimatedDivider from '../departments/DeptHeading';

const { navbarColor, bigtext } = colorConfig2;

function Syllabus() {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openPDF = (pdfUrl) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const CustomTabs = styled(Tabs)(({ theme }) => ({
    '.MuiTabs-indicator': {
      backgroundColor: navbarColor
    },
    '.MuiTab-textColorInherit': {
      color: navbarColor
    }
  }));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabsData = {
    tabs: [
      {
        id: 0,
        label: 'BDS syllabus',
        content: '<p>BDS Content</p>',
        bdssyllabus: {
          heading: 'BDS syllabus'
        }
      },
      {
        id: 1,
        label: 'MDS syllabus',
        content: '<p>MDS Content</p>',
        mdssyllabus: {
          heading: 'MDS syllabus'
        }
      }
    ]
  };

  return (
    <>
      {' '}
      <AHeader header={'SYLLABUS'} isScrolled={true} />
      <div className="p-20vh" style={{ paddingLeft: '10vh', paddingRight: '10vh' }}>
        <div className="mx-2 card">
          <CustomTabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="darkorange"
          >
            {tabsData.tabs.map((tab) => (
              <Tab key={tab.id} label={tab.label} value={tab.id} />
            ))}
          </CustomTabs>
          <div>
            {tabsData.tabs.map(
              (tab) =>
                activeTab === tab.id && (
                  <div key={tab.id} className="mt-2 ml-2">
                    {tab.bdssyllabus &&
                      (isMobile ? (
                        <Button onClick={() => openPDF(bds)}>
                          BDS Syllabus? Please Click here
                        </Button>
                      ) : (
                        <div className="flex items-center justify-center p-4 bg-white shadow-lg rounded-xl">
                          <iframe
                            src={bds}
                            title="Syllabus"
                            className="w-full h-[1000px] rounded-lg"
                            style={{
                              border: 'none',
                              boxSizing: 'border-box'
                            }}
                          />
                        </div>
                      ))}
                    {tab.mdssyllabus &&
                      (isMobile ? (
                        <Button onClick={() => openPDF(mds)}>
                          MDS Syllabus? Please Click here
                        </Button>
                      ) : (
                        <div className="flex items-center justify-center p-4 bg-white shadow-lg rounded-xl">
                          <iframe
                            src={mds}
                            title="Syllabus"
                            className="w-full h-[1000px] rounded-lg"
                            style={{
                              border: 'none',
                              boxSizing: 'border-box'
                            }}
                          />
                        </div>
                      ))}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Syllabus;
