import React, { useEffect, useState } from 'react';

function PrincipalMessage({ colorConfig }) {
  const [activeSections, setActiveSections] = useState(new Set());

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const newActiveSections = new Set(activeSections);
        entries.forEach((entry) => {
          const sectionId = entry.target.id;
          if (entry.isIntersecting) {
            newActiveSections.add(sectionId); // Add section to active if intersecting
          }
        });
        setActiveSections(newActiveSections);
      },
      {
        threshold: 0.2 // Trigger when 20% of the section is visible
      }
    );

    const targets = document.querySelectorAll('.principal-message .animate-on-scroll');
    targets.forEach((target) => observer.observe(target));

    return () => {
      targets.forEach((target) => observer.unobserve(target));
    };
  }, [activeSections]);

  return (
    <>
      <style>{`
        .principal-message .transition-transform {
          transition: transform 0.7s ease, opacity 0.7s ease;
        }
        .principal-message .opacity-0 {
          opacity: 0;
        }
        .principal-message .opacity-100 {
          opacity: 1;
        }
        .principal-message .translate-y-20 {
          transform: translateY(20px);
        }
        .principal-message .translate-y-0 {
          transform: translateY(0);
        }
      `}</style>

      <div className="container px-5 mx-auto mt-16 mb-20 principal-message animate-float-in">
        {/* Animated Heading */}

        <div className="flex flex-col gap-8 md:flex-row">
          {/* Image on the left */}
          <div
            id="section-image"
            className={`md:w-1/3 order-1 mt-5 flex flex-col items-center animate-on-scroll transition-transform duration-700 ${
              activeSections.has('section-image')
                ? 'opacity-100 translate-y-0'
                : 'opacity-0 translate-y-20'
            }`}
            style={{ animationDelay: '0.3s' }}
          >
            <h2
              className="text-[color:var(--big-text)] text-2xl font-bold font-ebgaramond 
            text-center md:text-left"
            >
              Message From The Principal
            </h2>
            <img
              src="/assets/principal/principal.jpg"
              alt="Principal"
              className="rounded-full w-[230px] h-[230px] object-cover"
            />

            <div className="mt-4 text-center font-ebgaramond">
              <h3 className="text-[color:var(--text-color)] text-xl font-bold">
                Dr. D.B. Nandeeshwar
              </h3>
              <p className="text-[color:var(--text-color)] text-lg">Principal, Professor and HOD</p>
            </div>
          </div>

          {/* Text on the right */}
          <div
            id="section-text"
            className={`md:w-2/3 order-2 font-lora animate-on-scroll transition-transform duration-700 ${
              activeSections.has('section-text') ? 'opacity-100 translate-y-0' : ''
            }`}
            style={{ animationDelay: '0.6s' }}
          >
            <p className="text-[color:var(--small-text)] text-justify text-lg mb-4">
              Choosing Bapuji Dental College & Hospital (BDCH) means embarking on a transformative
              journey guided by Our esteemed and experienced faculty. Our commitment extends beyond
              academic excellence to fostering holistic personality development. We strive to help
              you unlock your fullest potential by immersing you in a rich reservoir of knowledge
              and experience. Our goal is to ensure that upon graduation, you are not only
              academically accomplished but also well-prepared for a fulfilling and successful life.
            </p>

            <p className="text-[color:var(--small-text)] text-justify text-lg">
              BDCH has earned a distinguished National and International reputation over more than
              40 years of dedicated service and excellence. Our outstanding student pass rates are a
              testament to our high standards, further highlighted by the numerous national awards
              our students have achieved. Many of Our Alumni have risen to prominent positions in
              Prestigious Colleges and Hospitals Worldwide, continuing to uphold the values and
              excellence that BDCH represents.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrincipalMessage;
