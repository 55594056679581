import React, { useState, useEffect } from 'react';
import { viewNews } from 'api-ws/news-ws';
import moment from 'moment';
import AHeader from '../components/AHeader';
import BDCH_GIF from 'assets/BDCH_GIF.gif';

function News() {
  const [news, setNews] = useState();
  const [loading, setLoading] = useState(false);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchNews();
  }, []);

  const fetchNews = () => {
    setLoading(true);
    viewNews()
      .then((res) => {
        setNews(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openPDF = (pdfUrl) => {
    const win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={BDCH_GIF} alt="Loading..." />
      </div>
    );
  }

  const PDFPreview = ({ pdfUrl }) => (
    <div onClick={() => openPDF(pdfUrl)} className="cursor-pointer group">
      <div
        className="overflow-hidden transition-all duration-300 bg-white border 
        border-gray-200 rounded-lg shadow-md hover:shadow-lg w-[250px]"
      >
        {/* PDF Preview */}
        <div className="relative w-full h-[150px]">
          <iframe
            src={pdfUrl}
            title="PDF Preview"
            className="w-full h-full"
            style={{ pointerEvents: 'none' }}
          />
          <div className="absolute inset-0 transition-colors duration-300 bg-black/5 group-hover:bg-black/0" />
        </div>

        {/* PDF Info */}
        <div className="p-2 border-t border-gray-200 bg-gray-50">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <svg
                className="w-4 h-4 text-red-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                />
              </svg>
              <span className="text-xs font-medium text-gray-700">View PDF</span>
            </div>
            <svg
              className="w-4 h-4 text-gray-400 transition-transform duration-300 transform group-hover:translate-x-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`${isMobile ? 'mt-[8vh]' : 'mt-[25vh]'}`}>
      <AHeader header={'NEWS & EVENTS'} isScrolled={true} />

      <div className="px-10 md:pr-0">
        <div className="w-full divide-y divide-gray-200">
          {news?.map((item, index) => (
            <div key={index} className="py-6">
              <div className="flex gap-6">
                {/* PDF Link */}
                {item?.pdfFiles && <PDFPreview pdfUrl={item.pdfFiles} />}
                <div className="flex-1 space-y-4">
                  {/* Title */}
                  <h2 className="text-xl font-semibold text-gray-900">{item?.title}</h2>

                  {/* Description */}
                  <p className="overflow-auto text-base text-gray-600">{item?.description}</p>

                  {/* Metadata */}
                  <div className="flex flex-wrap items-center gap-6 text-sm text-gray-500">
                    {/* Date */}
                    <div className="flex items-center">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="ml-2">
                        {moment(item?.createdDate).format('DD MMM YYYY')}
                      </span>
                    </div>

                    {/* Author */}
                    <div className="flex items-center">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        />
                      </svg>
                      <span className="ml-2">{item?.newsaddedby}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default News;
