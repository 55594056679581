import React, { useEffect } from 'react';
import img1 from './aboutbdchpic.webp';
import img2 from './gradf.webp';
import img3 from './bdch.webp';
import VmSection from './visionmission';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <div
        className="relative h-[60vh] sm:h-[80vh] bg-cover bg-center px-2.5 sm:px-5 flex items-center justify-center"
        style={{ backgroundImage: `url(${img1})` }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-center p-5 text-center bg-black/40">
          <h1 className="mb-2 text-xl font-bold text-white font-georgia sm:text-3xl">About BDCH</h1>
          <h2 className="max-w-full px-4 text-sm font-bold text-white break-words font-georgia sm:text-2xl">
            Nurturing Thousands of Dentists to reach the Zenith of their Profession.
          </h2>
        </div>
      </div>

      {/* Tagline Section */}
      <div className="flex justify-center items-center mt-5 mb-5 text-center px-2.5 sm:px-0">
        <p className="w-full text-base whitespace-pre-line sm:text-4xl font-satoshi">
          This College has established itself as a Highly Vibrant {'\n'}
          and Dynamic Institution in the Realm of Dental Education
        </p>
      </div>

      {/* Stats Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8 mx-2.5 sm:mx-10">
        <div>
          <img src={img2} alt="Student Stats" className="w-full h-auto" />
        </div>

        <div className="text-center">
          <div className="mb-5">
            <p className="text-2xl sm:text-4xl text-red-600 mb-2.5">3800+ & 1350+</p>
            <p className="text-xs sm:text-xl">Undergraduate and Postgraduate Students</p>
          </div>
          <div className="mb-5">
            <p className="text-2xl sm:text-4xl text-red-600 mb-2.5">88+</p>
            <p className="text-xs sm:text-xl">Renowned Faculty Members</p>
          </div>
          <div>
            <p className="text-2xl sm:text-4xl text-red-600 mb-2.5">5000+</p>
            <p className="text-xs sm:text-xl">Alumni Worldwide</p>
          </div>
        </div>
      </div>

      {/* History Section */}
      <div className="bg-[#E8D8C4] mt-2.5 sm:-mt-2 p-5">
        <h2 className="mb-5 text-xl font-bold text-center sm:text-3xl font-satoshi">
          History of Bapuji Dental College And Hospital
        </h2>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="flex justify-center">
            <img src={img3} alt="Bapuji Dental College" className="w-full h-auto" />
          </div>
          <div className="flex items-center">
            <p className="text-sm text-justify sm:text-lg">
              Driven by a passion to establish World-Class Institutions, Bapuji Dental College was
              founded in 1979 by the esteemed Dr. Shamanur Shivashankarappa. Under the guidance of
              visionary leaders in Indian Dentistry, the college has experienced remarkable growth,
              celebrating its silver jubilee in 2004.
              <br />
              <br />
              Bapuji Dental College stands out as one of the most vibrant and dynamic institutions
              in Dental Education. It has nurtured thousands of dentists to reach the pinnacle of
              their profession. Since its inception, the college has significantly contributed to
              India's rich legacy of knowledge, education, and enlightenment. The state-of-the-art
              institute offers students exceptional clinical education, complemented by research
              opportunities and community service.
              <br />
              <br />
              The college features some of the finest lab facilities, including sophisticated
              phantom head labs with 100 Dental workstations for pre-clinical training, simulating
              real dental treatments. It also boasts a Ceramic Lab for crown and bridge fabrication,
              providing students with valuable technical skills in dentistry. Additionally, the
              college prides itself on its extensive library, which is well-ventilated and
              illuminated, creating an ideal environment for avid readers. The library is equipped
              with modern resources, including CD-ROMs, online databases, video cassettes, books,
              journals, back volumes, theses, and WHO publications. It serves as a comprehensive
              resource center for students, offering everything from references to printouts and
              binding services.
            </p>
          </div>
        </div>
      </div>

      {/* Vision & Mission Section */}
      <div className="w-full p-5 text-center bg-[#F3F3F3]">
        <h2 className="text-3xl font-bold font-satoshi text-gray-800 mt-2.5 mb-5">
          Our Vision & Mission
        </h2>
        <VmSection />
      </div>
    </div>
  );
};

export default AboutUs;
