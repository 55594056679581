import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import AHeader from '../components/AHeader';
import img1 from './sss1.webp';
import img2 from './veeranna.webp';
import img3 from './prabha.webp';
import img4 from './nuthalik.webp';
import img5 from './malllikarjun.webp';
import img6 from './naddeshwar.webp';

const Management = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [activeSections, setActiveSections] = useState(new Set());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const newActiveSections = new Set(activeSections);
        entries.forEach((entry) => {
          const sectionId = entry.target.id;
          if (entry.isIntersecting) {
            newActiveSections.add(sectionId); // Add section to active if in view
          }
        });
        setActiveSections(newActiveSections);
      },
      {
        threshold: 0.2 // Trigger when 20% of the element is visible
      }
    );

    const targets = document.querySelectorAll('.animate-on-scroll');
    targets.forEach((target) => observer.observe(target));

    return () => {
      targets.forEach((target) => observer.unobserve(target));
    };
  }, [activeSections]);

  const adminData = [
    {
      name: 'Dr. Shamanur Shivashankarappa',
      designation: 'Chairman',
      image: img1
    },
    {
      name: 'Sri. A.S Veeranna',
      designation: 'Member',
      image: img2
    },
    {
      name: 'Dr. Prabha Mallikarjun',
      designation: 'Member',
      image: img3
    },
    {
      name: 'Sri. Sampanna Muthalik',
      designation: 'Member',
      image: img4
    },
    {
      name: 'Sri. S.S Mallikarjun',
      designation: 'Member',
      image: img5
    },
    {
      name: 'Dr. Nandeeshwar D B',
      designation: 'Convener',
      image: img6
    }
  ];

  return (
    <>
      <AHeader header={'BDCH GOVERNING BODY'} isScrolled={true} />
      <Container>
        <div className="flex flex-col items-center gap-6 mt-5 mt-10 font-lora md:flex-row md:items-start">
          <img
            src="/assets/management/roundtable121.webp"
            alt="Bapuji Educational Association"
            className="object-cover w-full h-64 mt-5 rounded-lg md:w-1/2 lg:w-2/5 md:h-80"
          />

          <div className="space-y-4 text-base text-justify text-gray-700 md:text-lg">
            <p
              id="section-heading"
              className={`animate-on-scroll transition-transform duration-700 ${
                activeSections.has('section-heading')
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 translate-y-20'
              }`}
            >
              <strong className="text-lg font-semibold">
                Bapuji Educational Association (BEA)
              </strong>{' '}
              rightly named after the Father of Our Nation 'Bapuji,' was the Brain Child of a Team
              of Spirited Philanthropists led by visionary Dr. Shamanur Shivashankarappa and found
              its inception in the year 1958 following the inspirational foresight of Pandit
              Jawaharlal Nehru.
            </p>
            <p
              id="section-heading2"
              className={`animate-on-scroll transition-transform duration-700 ${
                activeSections.has('section-heading2')
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 translate-y-20'
              }`}
            >
              As a Devoted Freedom Fighter and the First Prime Minister of Free India, Nehru
              strongly believed that the only powerful weapon that can drive out illiteracy and
              poverty was Education which should reach every Indian through "Temples of wisdom," as
              he duly referred to Educational Institutions.
            </p>
            <p
              id="section-heading3"
              className={`animate-on-scroll transition-transform duration-700 ${
                activeSections.has('section-heading3')
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 translate-y-20'
              }`}
            >
              With such an ignited root, Bapuji Educational Association in over 50 years of her
              livelihood has not only branched out the shoots into many fields of education but also
              ensured quality in every step. From corporate science to health care science, the
              association has left a mark in the entire venture, and the banner gives tough
              competition to her peers in the country.
            </p>
            <p
              id="section-heading4"
              className={`animate-on-scroll transition-transform duration-700 ${
                activeSections.has('section-heading4')
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 translate-y-20'
              }`}
            >
              Today BEA is a global face that attracts students from overseas who choose to pursue
              their education in her shadow of tradition and poise.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8 mt-5 md:grid-cols-2 lg:grid-cols-3 justify-items-center">
          {adminData.map((person, index) => (
            <div key={index} className="w-96">
              <div className="flex flex-col items-center justify-between h-[600px] p-6 overflow-hidden transition-all duration-300 bg-white shadow-lg rounded-xl hover:shadow-xl group">
                <div className="relative w-80 h-96">
                  <div className="relative w-full h-full overflow-hidden rounded-[40px] bg-white">
                    <img
                      src={person.image}
                      alt={`${person.name}'s picture`}
                      className="object-cover w-full h-full transition duration-300 transform group-hover:scale-105 group-hover:brightness-90"
                    />
                  </div>
                </div>

                {/* Text Section */}
                <div className="flex-grow text-center mt-6 w-full bg-[#00B4AC]/5 rounded-lg p-4">
                  <h3 className="text-xl font-bold mb-2 leading-tight line-clamp-2 bg-gradient-to-r from-[#0352A1] via-[#0180A6] to-[#00B4AC] text-transparent bg-clip-text">
                    {person.name}
                  </h3>
                  <p className="text-sm text-[#0180A6] font-medium tracking-wide truncate">
                    {person.designation}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>

      {/* Styles */}
      <style jsx>{`
        .animate-on-scroll {
          opacity: 0;
          transform: translateY(20px);
          transition: opacity 0.7s ease, transform 0.7s ease;
        }
        .animate-on-scroll.opacity-100 {
          opacity: 1;
          transform: translateY(0);
        }
      `}</style>
    </>
  );
};

export default Management;
