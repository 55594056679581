import React, { useState, useEffect } from 'react';
import Overview from './Overview';
import Collections from './Collections';
import Services from './Services';
import AHeader from '../components/AHeader';
import ImageGallery from './libimagegallery';

const LibraryPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabsData = {
    tabs: [
      {
        id: 0,
        label: 'Overview',
        overview: {
          heading: 'ADMISSION PROCEDURE FOR BDS & MDS COURSES'
        }
      },
      {
        id: 1,
        label: 'Collections',
        collections: {
          heading: 'af'
        }
      },
      {
        id: 2,
        label: 'Facilities and Services',
        services: {
          heading: 'kc'
        }
      },
      {
        id: 3,
        label: 'Image Gallery',
        imagegallery: {
          heading: 'kc'
        }
      }
    ]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <AHeader header={'LIBRARY'} isScrolled={true} />

      <div className="container px-4 py-8 mx-auto">
        <div className="overflow-hidden bg-white shadow-lg rounded-xl">
          {/* Tabs Navigation */}
          <div className="border-b border-gray-200">
            {/* Mobile Tabs */}
            <div className="flex flex-col sm:hidden">
              {tabsData.tabs.map((tab, index) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`
                    relative w-full px-6 py-4 text-left
                    text-base font-medium font-garamond
                    transition-all duration-300 ease-in-out
                    ${
                      activeTab === tab.id
                        ? 'text-[#0180A6] bg-blue-50/50 border-l-4 border-[#0180A6]'
                        : 'text-gray-500 hover:text-[#0180A6] hover:bg-blue-50/30 border-l-4 border-transparent'
                    }
                    ${index === 0 ? 'rounded-t-xl' : ''}
                    ${index === tabsData.tabs.length - 1 ? 'rounded-b-xl' : ''}
                  `}
                >
                  {tab.label}
                </button>
              ))}
            </div>

            {/* Desktop Tabs */}
            <div className="hidden sm:flex">
              {tabsData.tabs.map((tab, index) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`
                    relative flex-1 px-6 py-4
                    text-base font-medium font-garamond
                    transition-all duration-300 ease-in-out
                    ${
                      activeTab === tab.id
                        ? 'text-[#0180A6] bg-blue-50/50 border-b-4 border-[#0180A6]'
                        : 'text-gray-500 hover:text-[#0180A6] hover:bg-blue-50/30 border-b-4 border-transparent'
                    }
                    ${index === 0 ? 'rounded-tl-xl' : ''}
                    ${index === tabsData.tabs.length - 1 ? 'rounded-tr-xl' : ''}
                  `}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>

          {/* Tab Content */}
          <div className="p-6">
            {tabsData.tabs.map(
              (tab) =>
                activeTab === tab.id && (
                  <div key={tab.id} className="space-y-6 animate-fadeIn">
                    {tab.overview && <Overview overviewData={tab.overview} />}
                    {tab.collections && <Collections collectionsData={tab.collections} />}
                    {tab.services && <Services servicesData={tab.services} />}
                    {tab.imagegallery && <ImageGallery imageGalleryData={tab.imagegallery} />}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryPage;
