import { Button, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';

function AboutUsText({ colorConfig }) {
  const { textColor } = colorConfig;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const props = useSpring({
    from: { number: 0 },
    to: { number: inView ? 1979 : 0 },
    config: { duration: 1000 }
  });

  const buttonSlideStyle = useSpring({
    from: {
      transform: isMobile ? 'translateX(0)' : 'translateX(100px)',
      opacity: isMobile ? 1 : 0
    },
    to: {
      transform: isMobile ? 'translateX(0)' : inView ? 'translateX(0)' : 'translateX(100px)',
      opacity: isMobile ? 1 : inView ? 1 : 0
    },
    config: { duration: 1000 }
  });

  const numberAnimation = useSpring({
    from: { number: isMobile ? 1979 : 0 },
    to: { number: isMobile ? 1979 : inView ? 1979 : 0 },
    config: { duration: 1000 }
  });

  return (
    <Grid
      xs={12}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: isMobile ? '2vh' : '4vh',
        padding: isMobile ? '2vh' : '10vh'
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography style={{ fontSize: '30px', marginBottom: '10px' }}>
          <i>
            <b>ABOUT BDCH</b>
          </i>
        </Typography>
        <Typography style={{ fontSize: '20px', textAlign: 'center', fontFamily: 'Lora, serif' }}>
          Fired by the zeal to create World-Class Institutions, Bapuji Dental College was founded in
          1979 by Dr. Shamanur Shivashankarappa. Guided by visionary leaders in Indian dentistry,
          the college has grown immensely, celebrating its silver jubilee in 2004. Under the able
          guidance of various stalwarts who served as transformation guardians, it has evolved from
          being one of India's Best Dental Colleges to one of Asia's finest.
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        <Grid
          item
          xs={5}
          md={7}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography
            component={animated.div}
            ref={ref}
            style={{
              fontSize: '40px',
              fontWeight: 'bold',
              fontFamily: 'Lora, serif'
            }}
          >
            {props.number.to((n) => Math.floor(n))}
          </Typography>
          <Typography style={{ fontSize: '20px', fontFamily: 'Lora, serif' }}>
            45 Glorious Years of Excellence
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Divider
            orientation="vertical"
            sx={{ height: '80%', width: 2, backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          md={3.5}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <animated.div style={buttonSlideStyle}>
            <Button
              component={Link}
              to="/aboutUs"
              variant="contained"
              style={{
                backgroundColor: textColor,
                color: 'whitesmoke',
                fontSize: '17px',
                fontFamily: 'Lora, serif'
              }}
            >
              Curious? <br /> Find out more!
            </Button>
          </animated.div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AboutUsText;
