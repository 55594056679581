import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import Vision from './vision.webp';
import Mission from './mission.webp';

const VmSection = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const Data = [
    {
      heading: 'Our Mission',
      image: Mission,
      text: [
        'Learner centered Dental Education',
        'Patient Centered Service',
        'Community oriented Research',
        'Strong Community Relationship',
        'Serve the Under Served',
        'Meet the Regional, National and Global Dental Educational needs',
        'Inter organizational needs',
        'Strategic future oriented planning',
        'Excellence in knowledge, skills and services',
        'Professionalism in Management',
        'Open Organizational Climate'
      ]
    },
    {
      heading: 'Our Vision',
      image: Vision,
      text: [
        'Single Window Delivery of Total Oral Healthcare Needs',
        'Total Quality Management in Service & Education',
        'Unique Work Culture in alleviating Human Oral Sufferings',
        'To Train General, Specialized & Allied Professional Personnel to meet Regional & National Oral Health Care Services',
        'Work Relentlessly to contribute to Global Oral Health Care Knowledge & Skills',
        'Be efficient, effective, community acceptable and excel in service, education & research',
        'To Impart Knowledge & Interact with Organizations of Similar Interest',
        'Fostering Global Competencies, inculcating value system among learners',
        'Promote use of Technology of Relevance',
        'To Induce Paradigm Shift in Community that many oral diseases are preventable, curable & affordable',
        'Reach the unreachable with Awareness, Education & Service',
        'Serve the under served'
      ]
    }
  ];

  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '10px',
    height: isSmallScreen ? '850px' : '700px',
    display: 'flex',
    flexDirection: 'column'
  };

  const cardHeaderStyle = {
    color: '#333',
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center'
  };

  return (
    <div className="container px-4 py-8 mx-auto">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:items-stretch">
        {Data.map((data, index) => (
          <div key={index} className="flex flex-col h-full">
            <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg shadow-lg">
              {/* Card Header */}
              <h2 className="py-4 text-2xl font-bold text-center text-gray-800 md:text-3xl font-ebgaramond">
                {data.heading}
              </h2>

              {/* Card Image */}
              <div className="flex justify-center flex-shrink-0 px-6 pb-6">
                <img src={data.image} alt={data.heading} className="object-contain h-auto w-80" />
              </div>

              {/* Card Content */}
              <div className="flex-grow px-6 pb-6">
                <ul className="space-y-2">
                  {data.text.map((line, i) => (
                    <li key={i} className="flex items-start gap-3 text-start">
                      <span className="flex-shrink-0 inline-block w-2 text-blue-600">•</span>
                      <span className="flex-1 text-base text-gray-700">{line}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VmSection;
