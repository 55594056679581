import React, { useEffect } from 'react';

const ContactSuccessDialog = ({ setShowSuccess }) => {
  // Add fade-out animation
  useEffect(() => {
    // Make sure the dialog is visible initially
    const dialog = document.getElementById('success-dialog');
    if (dialog) {
      dialog.classList.add('opacity-100');
    }

    const timer = setTimeout(() => {
      if (dialog) {
        dialog.classList.replace('opacity-100', 'opacity-0');
        setTimeout(() => setShowSuccess(false), 500);
      }
    }, 2500);

    return () => clearTimeout(timer);
  }, [setShowSuccess]);

  return (
    <div
      id="success-dialog"
      className="fixed inset-x-0 z-50 flex justify-center px-4 transition-opacity duration-500 top-20"
      style={{ animation: 'fadeIn 0.5s forwards' }}
    >
      <div className="w-full max-w-md p-4 transform border border-green-200 rounded-lg shadow-lg bg-green-50 animate-bounce-in">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="p-2 mr-3 bg-green-100 rounded-full">
              <svg
                className="w-6 h-6 text-green-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <span className="font-medium text-green-800">Message sent successfully!</span>
          </div>
          <button
            onClick={() => {
              const dialog = document.getElementById('success-dialog');
              dialog.classList.add('opacity-0');
              setTimeout(() => setShowSuccess(false), 500);
            }}
            className="text-gray-500 hover:text-gray-700"
            aria-label="Close"
            style={{
              border: 'none',
              background: 'transparent',
              outline: 'none',
              padding: 0,
              margin: 0,
              boxShadow: 'none'
            }}
          >
            <svg
              className="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactSuccessDialog;
