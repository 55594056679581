import React, { useState, useCallback, useEffect } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import AHeader from '../components/AHeader';
import departmentData from './departmentData.json';
import { useParams } from 'react-router-dom';

const Cases = () => {
  const { id } = useParams();
  const department = departmentData[id];
  const casesData = department?.cases;
  const [selectedCase, setSelectedCase] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openImageViewer = useCallback(
    (index) => {
      const images = selectedCase.images.map((img) => img.src);
      setCurrentImages(images);
      setCurrentImage(index);
      setIsViewerOpen(true);
    },
    [selectedCase]
  );

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="min-h-screen ">
      <AHeader header="Clinical Cases" isScrolled={true} />

      <div className="container px-4 py-8 mx-auto">
        {!selectedCase ? (
          <div className="max-w-4xl mx-auto">
            {' '}
            {/* Increased max-width */}
            <div className="overflow-hidden shadow-md rounded-xl">
              <div className="p-2 space-y-2">
                {' '}
                {/* Added spacing between items */}
                {casesData?.map((caseItem, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedCase(caseItem)}
                    className="w-full px-6 py-5 text-left transition-colors duration-300 
                hover:bg-[#00B4AC]/10 focus:outline-none group
                bg-white rounded-lg shadow-sm hover:shadow-md" /* Added individual item styling */
                  >
                    <div className="flex items-center justify-between">
                      <h3 className="text-lg font-medium text-[#0352A1] font-ebgaramond">
                        {caseItem.title}
                      </h3>
                      <svg
                        className="w-5 h-5 text-[#0352A1] transform group-hover:translate-x-1 
                    transition-transform"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="animate-fadeIn">
            <div className="max-w-6xl mx-auto">
              <button
                onClick={() => setSelectedCase(null)}
                className="mb-6 flex items-center text-[#0352A1] hover:text-[#00B4AC] 
                  transition-colors duration-300 group"
              >
                <svg
                  className="w-5 h-5 mr-2 transition-transform transform group-hover:-translate-x-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <span className="text-lg font-medium font-ebgaramond">Back to Cases</span>
              </button>

              <h2 className="text-2xl font-bold font-ebgaramond text-[#0352A1] mb-6">
                {selectedCase.title}
              </h2>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                {selectedCase.images.map((image, index) => (
                  <div key={index} className="flex justify-center">
                    <div
                      className="w-full max-w-[320px] overflow-hidden rounded-lg shadow-lg 
                      hover:shadow-xl transition-all duration-300 
                      bg-[#00B4AC]/10 hover:bg-[#00B4AC]/20 p-2"
                    >
                      <div className="relative h-[200px] w-full">
                        <img
                          src={image.src}
                          alt={image.alt}
                          onClick={() => openImageViewer(index)}
                          className="absolute inset-0 w-full h-full rounded-lg cursor-pointer object-fit"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {isViewerOpen && (
        <div
          className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-80"
          role="dialog"
          aria-modal="true"
          aria-labelledby="image-viewer-title"
        >
          <div className="relative w-full max-w-[95vw] max-h-[95vh] overflow-hidden">
            <button
              onClick={closeImageViewer}
              className="absolute top-4 right-4 z-[10000] p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70 transition-all"
              aria-label="Close image viewer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
            <span id="image-viewer-title" className="sr-only">
              Image Viewer
            </span>
            <ImageViewer
              src={currentImages}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              backgroundStyle={{ backgroundColor: 'transparent' }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Cases;
