import React from 'react';
import { useNavigate } from 'react-router-dom';
import cardContent from './cardContent.json';

function DepartmentCards({ colorConfig }) {
  const { bigtext } = colorConfig;
  const navigate = useNavigate();

  const CardClick = (url) => {
    navigate(`${url}`);
  };

  return (
    <div className="px-5 mt-16 mb-20 md:px-8">
      {/* Title Section */}
      <div className="mb-6 text-center md:text-left md:pl-8">
        <h2 className="text-[color:var(--big-text)] text-2xl font-bold font-ebgaramond">
          Departments
        </h2>
      </div>

      {/* Cards Grid */}
      <div className="grid grid-cols-1 gap-4 px-2 sm:grid-cols-2 md:grid-cols-4 md:px-8">
        {cardContent.map((content, index) => (
          <div key={index} className="flex justify-center">
            <div
              onClick={() => CardClick(content.url)}
              className="max-w-[320px] w-full bg-white rounded-lg shadow-md 
                hover:shadow-xl transition-all duration-300 hover:scale-105 
                cursor-pointer overflow-hidden"
            >
              <div className="relative">
                <img
                  src={content.image}
                  alt={content.title}
                  className="w-full h-[200px] object-cover"
                />
                <div className="px-4">
                  <p className="text-center text-base font-lora text-[color:var(--big-text)]">
                    {content.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DepartmentCards;
