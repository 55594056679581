import React, { useEffect, useState } from 'react';
import { getAllDirectory } from 'api-ws/aluminiDirectory-ws';
import AHeader from '../components/AHeader';

const StudentDirectory = () => {
  const [bdsStudentList, setBdsStudentList] = useState();
  const [mdsStudentList, setMdsStudentList] = useState();

  useEffect(() => {
    fetchDirectoryDetails();
    window.scrollTo(0, 0);
  }, []);

  const fetchDirectoryDetails = async () => {
    try {
      const res = await getAllDirectory();
      setBdsStudentList(res?.data?.data[0]?.bdsStudentlist);
      setMdsStudentList(res?.data?.data[0]?.mdsStudentList);
    } catch (err) {
      console.log(err);
    }
  };

  const openPDF = (pdfUrl) => {
    const win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  const DirectoryCard = ({ title, pdfList, onClick }) => (
    <div className="max-w-2xl mx-auto">
      <div className="overflow-hidden bg-white shadow-lg rounded-xl">
        {/* Header */}
        <div className="bg-gradient-to-r from-[#1e3a8a] to-[#2563eb] py-4">
          <h2 className="text-xl font-bold text-center text-white sm:text-2xl font-ebgaramond">
            {title}
          </h2>
        </div>

        {/* Button */}
        <div className="p-0.5  bg-gradient-to-r from-[#1e3a8a] via-[#2563eb] to-[#1e3a8a]">
          <button
            onClick={() => onClick(pdfList)}
            className="w-full rounded-xl py-4 px-6 text-sm sm:text-base font-lora 
              text-gray-700 bg-white transition-all duration-300
              hover:text-blue-600 hover:bg-blue-50/90
              focus:outline-none active:scale-[0.99]"
          >
            {title} Students List
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <AHeader header="Student Directory" />

      <div className="container px-4 py-8 mx-auto space-y-8">
        <DirectoryCard title="BDS" pdfList={bdsStudentList} onClick={openPDF} />
        <DirectoryCard title="MDS" pdfList={mdsStudentList} onClick={openPDF} />
      </div>
    </div>
  );
};

export default StudentDirectory;
