import React from 'react';
import img1 from './principal.jpg';
import principalData from './principalData.json';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import { useMediaQuery } from '@mui/material';
import AHeader from '../components/AHeader';
import colorConfig2 from 'app/color/colorConfig2';

const { smalltext } = colorConfig2;

const Director = () => {
  const { principal } = principalData;
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className="container mx-auto">
      <AHeader header={'MESSAGE FROM PRINCIPAL'} isScrolled={true} />

      <div className="flex flex-wrap p-4">
        <div className="w-full">
          {/* <div className="w-full p-8 bg-white rounded-lg shadow-[0_10px_20px_rgba(0,0,0,0.1)] hover:shadow-[0_20px_30px_rgba(0,0,0,0.15)] transition-all duration-300"> */}
          <div className="flex flex-wrap">
            <div className="flex flex-col w-full md:w-1/3">
              <div className="relative flex items-center justify-center p-1 rounded-xl before:absolute before:inset-0 before:blur-lg before:bg-gradient-to-r before:from-[#0352A1] before:via-[#0180A6] before:to-[#00B4AC] before:-z-10 hover:scale-[1.02] transition-transform duration-300 ease-in-out">
                <img
                  src={img1}
                  alt="Director"
                  className="h-[60vh] w-full object-cover rounded-xl border-[4px] border-white shadow-[0_4px_10px_rgba(0,0,0,0.15)] hover:border-[#0180A6] hover:shadow-xl transition-all duration-300"
                />
              </div>
              <div className="font-ebgaramond mt-4 border-2 border-[#0352A1]/20 px-4 py-2 rounded-lg text-2xl font-bold text-center bg-[#f0f8ff] hover:bg-[#0352A1]/5 transition-all duration-300 shadow-[0_2px_6px_rgba(0,0,0,0.1)] hover:shadow-[0_4px_12px_rgba(0,0,0,0.15)]">
                <span className="bg-gradient-to-r from-[#0352A1] via-[#0180A6] via-[#00B4AC] to-[#0352A1] text-transparent bg-clip-text">
                  Dr. NANDEESHWAR D.B.
                </span>
                <br />
                <p className="text-base font-ebgaramond mt-1 bg-gradient-to-r from-[#0352A1] via-[#0180A6] via-[#00B4AC] to-[#0352A1] text-transparent bg-clip-text">
                  Principal, Professor and HOD
                </p>
              </div>
            </div>

            <div className={`w-full ${isMobile ? 'mt-6 px-0' : 'px-8'} md:w-2/3`}>
              {' '}
              <div className="relative text-[#2C3E50] text-justify text-xl leading-relaxed p-6 bg-[#f9f9f9] rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.1)] hover:shadow-[0_6px_15px_rgba(0,0,0,0.15)] transition-all duration-300">
                <div
                  className="absolute inset-0 bg-center bg-no-repeat opacity-[0.15] pointer-events-none"
                  style={{
                    backgroundImage: "url('/assets/logo/logo.png')",
                    filter: 'grayscale(60%) contrast(1.4)',
                    backgroundSize: '60%'
                  }}
                ></div>
                <div className="relative z-10 text-lg font-lora">
                  <strong className="text-[#112D58] text-xl font-ebgaramond">
                    {principal?.message1}
                  </strong>
                  <br />
                  <br />
                  {principal?.message2}
                  {principal?.message3}
                  <br />
                  {principal?.message4}
                  <br />
                  {principal?.message5}
                  <br />
                  {principal?.message6}
                  <br />
                  <br />
                  {principal?.message7}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Director;
