import React, { useState, useEffect } from 'react';
import Procedure from './Procedure';
import Forms from './Forms';
import Keycontact from './Keycontact';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import AHeader from '../components/AHeader';

const AdmissionPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabsData = [
    {
      id: 0,
      label: 'Application Procedures',
      procedure: {
        heading: 'ADMISSION PROCEDURE FOR BDS & MDS COURSES'
      }
    },
    {
      id: 1,
      label: 'Application Form',
      forms: {
        heading: 'af'
      }
    },
    {
      id: 2,
      label: 'Key Contact',
      keycontact: {
        heading: 'kc'
      }
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <AHeader header={'ADMISSIONS'} isScrolled={true} />

      <div className="container px-4 py-8 mx-auto">
        <div className="flex flex-col gap-6 md:flex-row">
          {/* Main Content */}
          <div className="w-full md:w-3/4">
            <div className="overflow-hidden bg-white shadow-lg rounded-xl">
              {/* Tabs Container */}
              <div className="border-b border-gray-200">
                {/* Mobile Tabs - Vertical */}
                <div className="flex flex-col sm:hidden">
                  {tabsData.map((tab, index) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`
                        relative w-full px-6 py-4 text-left
                        text-base font-medium font-garamond
                        transition-all duration-300
                        ${
                          activeTab === tab.id
                            ? 'text-[#0180A6] bg-blue-50/50 border-l-4 border-[#0180A6]'
                            : 'text-gray-500 hover:text-[#0180A6] hover:bg-blue-50/30 border-l-4 border-transparent'
                        }
                        ${index === 0 ? 'rounded-t-xl' : ''}
                        ${index === tabsData.length - 1 ? 'rounded-b-xl' : ''}
                      `}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>

                {/* Desktop Tabs - Horizontal */}
                <div className="hidden sm:flex">
                  {tabsData.map((tab, index) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`
                        relative flex-1 px-6 py-4
                        text-base font-medium font-garamond
                        transition-all duration-300
                        ${
                          activeTab === tab.id
                            ? 'text-[#0180A6] bg-blue-50/50 border-b-4 border-[#0180A6]'
                            : 'text-gray-500 hover:text-[#0180A6] hover:bg-blue-50/30 border-b-4 border-transparent'
                        }
                        ${index === 0 ? 'rounded-tl-xl' : ''}
                        ${index === tabsData.length - 1 ? 'rounded-tr-xl' : ''}
                      `}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>
              </div>

              {/* Tab Content */}
              <div className="p-6">
                {tabsData.map(
                  (tab) =>
                    activeTab === tab.id && (
                      <div key={tab.id} className="animate-fadeIn">
                        {tab.procedure && <Procedure procedureData={tab.procedure} />}
                        {tab.forms && <Forms formsData={tab.forms} />}
                        {tab.keycontact && <Keycontact keycontactData={tab.keycontact} />}
                      </div>
                    )
                )}
              </div>
            </div>
          </div>

          {/* News Banner - Always visible on desktop */}
          <div className="hidden md:block md:w-1/4">
            <VerticalNewsBanner />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionPage;
