import React from 'react';
import ScrollNavbar from './components/ScrollNavbar';
import { Route, Routes } from 'react-router-dom';
import HomePage from './home/HomePage';
import AboutUs from './aboutus/AboutUs';
import Prospectus from './aboutus/Prospectus';
import Director from './administration/Director';
import Principal from './administration/Principal';
import Committees from './administration/Committees';
import Management from './administration/Management';
import Affiliation from './administration/Affiliation';
import Rti from './administration/Rti';
import AcademicDetails from './academic-affairs/AcademicDetails';
import ExamSchedule from './academic-affairs/ExamSchedule';
import Admission from './academic-affairs/Admission';
import Vision from './vision/Vision';
import News from './news/News';
import Image from './gallery/Image';
import Video from './gallery/Video';
import Nirf from './nirf/Nirf';
import Contact from './contact/Contact';
import Students from './academic-affairs/Students';
import Library from './academic-affairs/Library';
import Hostel from './academic-affairs/Hostel';

import Footer from './footer/Footer';
import Overview from './departments/Overview';
import HodMessage from './departments/HodMessage';
import Faculty from './departments/Faculty';
import Awards from './departments/Awards';
import Publications from './departments/Publications';
import Presentation from './departments/Presentation';
import Dissertation from './departments/Dissertation';
import Activities from './departments/Activities';
import Syllabus from './departments/DepartmentSyllabus';
import Gallery from './departments/DepartmentGallery';
import AlumniGallery from './alumni/alumniGallery/AlumniGallery';
import Syllabus1 from './Syllabus/Syllabus';
import VicePrincipalMessage from './home/VicePrincipalMessage';
import Cases from './departments/Cases';

function Bdch() {
  return (
    <>
      <ScrollNavbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/aboutus/prospectus" element={<Prospectus />} />
        <Route path="/administration/director" element={<Director />} />
        <Route path="/administration/principal" element={<Principal />} />
        <Route path="/administration/viceprincipal" element={<VicePrincipalMessage />} />
        <Route path="/administration/committees" element={<Committees />} />
        <Route path="/administration/management" element={<Management />} />
        <Route path="/administration/affiliation" element={<Affiliation />} />
        <Route path="/administration/rti" element={<Rti />} />

        <Route path="/academic-affairs/academic-details" element={<AcademicDetails />} />
        <Route path="/academic-affairs/exam-schedule" element={<ExamSchedule />} />
        <Route path="/academic-affairs/admission" element={<Admission />} />
        <Route path="/academic-affairs/hostel" element={<Hostel />} />
        <Route path="/academic-affairs/library" element={<Library />} />
        <Route path="/academic-affairs/students" element={<Students />} />

        <Route path="/departments/overview/:id" element={<Overview />} />
        <Route path="/departments/hod/:id" element={<HodMessage />} />
        <Route path="/departments/faculty/:id" element={<Faculty />} />
        <Route path="/departments/Activities/:id" element={<Activities />} />
        <Route path="/departments/awards/:id" element={<Awards />} />
        <Route path="/departments/publications/:id" element={<Publications />} />
        <Route path="/departments/presentation/:id" element={<Presentation />} />
        <Route path="/departments/dissertation/:id" element={<Dissertation />} />
        <Route path="/departments/syllabus/:id" element={<Syllabus />} />
        <Route path="/departments/gallery/:id" element={<Gallery />} />
        <Route path="/departments/cases/:id" element={<Cases />} />

        <Route path="/vision" element={<Vision />} />
        <Route path="/news" element={<News />} />

        <Route path="/gallery/image" element={<AlumniGallery />} />
        <Route path="/gallery/video" element={<Video />} />

        <Route path="/contact" element={<Contact />} />
        <Route path="/nirf" element={<Nirf />} />
        <Route path="/syllabus" element={<Syllabus1 />} />

        <Route path="/bapujidental/alumni/" element={<News />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Bdch;
