import React, { useEffect } from 'react';

import principalData from './principalData.json';

import AHeader from '../components/AHeader';
import { useState } from 'react';

const Committees = () => {
  const { cardContent } = principalData;
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const firstColumnContent = cardContent.slice(0, 16);
  const secondColumnContent = cardContent.slice(16);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AHeader header={'COMMITTEES'} isScrolled={true} />
      <div className="container px-4 mx-auto">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {/* First Column */}
          <div className="space-y-6">
            {firstColumnContent.map((content, index) => (
              <div key={index} className="w-full bg-white shadow-md rounded-xl">
                <button
                  onClick={() => toggleAccordion(index)}
                  className="w-full px-8 py-5 flex justify-between items-center bg-gradient-to-r from-white to-slate-50 hover:from-[#00B4AC]/5 hover:to-transparent transition-all duration-300 rounded-t-xl"
                >
                  <h3 className="text-lg font-ebgaramond font-semibold bg-gradient-to-r from-[#0352A1] via-[#0180A6] to-[#00B4AC] bg-clip-text text-transparent">
                    {content.title}
                  </h3>
                  <svg
                    className={`w-6 h-6 transform transition-all duration-300 text-[#00B4AC] ${
                      activeIndex === index ? 'rotate-180' : ''
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {activeIndex === index && (
                  <div className="px-8 py-5 border-t border-gray-200 bg-[#00B4AC]/5">
                    {content.fileUrl ? (
                      <a
                        href={content.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#0352A1] font-lora hover:text-[#00B4AC] transition-colors duration-300 flex items-center gap-2"
                      >
                        <span>View Document</span>
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          />
                        </svg>
                      </a>
                    ) : (
                      <p className="text-gray-500 font-lora">No document available</p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Second Column */}
          <div className="space-y-6">
            {secondColumnContent.map((content, index) => (
              <div key={index} className="w-full bg-white shadow-md rounded-xl">
                <button
                  onClick={() => toggleAccordion(index + firstColumnContent.length)} // Keep this calculation
                  className="w-full px-8 py-5 flex justify-between items-center bg-gradient-to-r from-white to-slate-50 hover:from-[#00B4AC]/5 hover:to-transparent transition-all duration-300 rounded-t-xl"
                >
                  <h3 className="text-lg font-semibold font-ebgaramond bg-gradient-to-r from-[#0352A1] via-[#0180A6] to-[#00B4AC] bg-clip-text text-transparent">
                    {content.title}
                  </h3>
                  <svg
                    className={`w-6 h-6 transform transition-all duration-300 text-[#00B4AC] ${
                      activeIndex === index + secondColumnContent.length ? 'rotate-180' : ''
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {activeIndex === index + firstColumnContent.length && (
                  <div className="px-6 py-4 border-t">
                    {content.fileUrl ? (
                      <a
                        href={content.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#00B4AC] font-lora hover:text-[#0352A1] transition-colors duration-200"
                      >
                        View Document
                      </a>
                    ) : (
                      <p className="text-gray-500 font-lora">No document available</p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Committees;
