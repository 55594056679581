import { useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

function VicePrincipalMessage({ colorConfig }) {
  const [activeSections, setActiveSections] = useState(new Set());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const newActiveSections = new Set(activeSections);
        entries.forEach((entry) => {
          const sectionId = entry.target.id;
          if (entry.isIntersecting) {
            newActiveSections.add(sectionId);
          }
        });
        setActiveSections(newActiveSections);
      },
      {
        threshold: 0.2
      }
    );

    const targets = document.querySelectorAll('.vice-principal-message .animate-on-scroll');
    targets.forEach((target) => observer.observe(target));

    return () => {
      targets.forEach((target) => observer.unobserve(target));
    };
  }, [activeSections]);

  return (
    <div className="container px-5 mx-auto mt-16 mb-20 vice-principal-message animate-float-in">
      <div className="flex flex-col gap-8 font-ebgaramond md:flex-row">
        {/* Image Section with Title */}
        <div
          id="section-image"
          className={`w-full md:w-1/3 flex flex-col justify-center items-center animate-on-scroll transition-transform duration-700 ${
            activeSections.has('section-image')
              ? 'opacity-100 translate-y-0'
              : 'opacity-0 translate-y-20'
          }`}
          style={{
            animationDelay: '0.3s',
            order: isMobile ? 1 : 2
          }}
        >
          {/* Title moved here */}
          <h2 className="text-[color:var(--big-text)] text-2xl font-ebgaramond font-bold mb-4 text-center">
            Message From The Vice Principal
          </h2>

          <img
            src="/assets/principal/viceprincipal.webp"
            alt="Vice Principal"
            className="rounded-full w-[260px] h-[260px] object-cover object-[center_top]"
          />

          <div className="mt-4 text-center">
            <h3 className="text-[color:var(--text-color)] text-xl font-bold">Dr.Ashok.L</h3>
            <p className="text-[color:var(--text-color)] text-lg">
              Vice Principal, Professor and HOD
            </p>
          </div>
        </div>

        {/* Text Section */}
        <div
          id="section-text"
          className={`md:w-2/3 order-${
            isMobile ? 2 : 1
          } font-lora animate-on-scroll transition-transform duration-700 ${
            activeSections.has('section-text')
              ? 'opacity-100 translate-y-0'
              : 'opacity-0 translate-y-20'
          }`}
          style={{ animationDelay: '0.6s' }}
        >
          <p className="text-[color:var(--small-text)] text-justify text-lg mb-4">
            It is with great pride and a profound sense of responsibility that I address you through
            this message.
            <br /> As the vice principal and the Head of the Department of Oral Medicine and
            Radiology, I am honored to be a part of this esteemed institution that stands as a
            centre of excellence in Dental education in India, for more than four decades. <br />{' '}
            Bapuji dental college and hospital is located in mid of Karnataka, is a second private
            dental college established in the year 1979 with the vision of training
            Gerneral,specialized and allied professional personnel to meet regional and National
            oral health care services, fostering global competencies, inculcating value systems
            among learners and to serve the underserved.
            <br /> Our institution is equipped with modern facilities and well equipped pre clinical
            and clinical facilities with highly qualified experience faculty to foster the rich
            clinical experience for the students.
            <br /> Our college provides comprehensive dental treatment for the public on an
            affordable price, thereby contributing significantly to public health.
          </p>

          <p className="text-[color:var(--small-text)] text-justify text-lg">
            BDCH has earned a distinguished National and International reputation over more than 40
            years of dedicated service and excellence. Our outstanding student pass rates are a
            testament to our high standards, further highlighted by the numerous national awards our
            students have achieved. Many of Our Alumni have risen to prominent positions in
            Prestigious Colleges and Hospitals Worldwide, continuing to uphold the values and
            excellence that BDCH represents.
          </p>
        </div>
      </div>

      <style jsx>{`
        #section-image {
          order: 1;
        }

        @media (min-width: 768px) {
          #section-image {
            order: 2;
          }
        }

        .vice-principal-message .transition-transform {
          transition: transform 0.7s ease, opacity 0.7s ease;
        }
        .vice-principal-message .opacity-0 {
          opacity: 0;
        }
        .vice-principal-message .opacity-100 {
          opacity: 1;
        }
        .vice-principal-message .translate-y-20 {
          transform: translateY(20px);
        }
        .vice-principal-message .translate-y-0 {
          transform: translateY(0);
        }
      `}</style>
    </div>
  );
}

export default VicePrincipalMessage;
