import React, { useEffect, useState } from 'react';
import { addAlumniContact } from 'api-ws/alumniContact-ws';
import ContactSuccessDialog from '../contact/ContactSuccess';
import AHeader from '../components/AHeader';

function AlumniContact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    email: ''
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: '', email: '' };

    if (!name.trim()) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    if (!email.trim()) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = 'Please enter a valid email';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const addContact = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const contact = {
      name: name,
      email: email,
      message: message
    };
    addAlumniContact(contact)
      .then((res) => {
        console.log(res);
        setName('');
        setEmail('');
        setMessage('');
        setShowSuccess(true);
      })
      .catch((e) => console.log('error', e));
  };

  return (
    <div className="mt-[8vh] md:mt-[25vh]">
      {showSuccess && <ContactSuccessDialog setShowSuccess={setShowSuccess} />}

      <AHeader header="ALUMNI CONTACT" />

      <div className="mx-4 mb-8">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.5442066055552!2d75.91392931432863!3d14.453411184542869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba2571eb98f1fd%3A0x1e9b7635c879caac!2sBapuji+Dental+College+and+Hospital!5e0!3m2!1sen!2sin!4v1506588013103"
          title="Maps Location"
          className="w-full h-[350px] border-0"
          allowFullScreen=""
        ></iframe>
      </div>

      <div className="container px-4 py-8 mx-auto">
        <div className="flex flex-col items-start gap-6 p-6 bg-white rounded-lg shadow-lg md:flex-row">
          {/* Address Card */}
          <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow-md md:w-1/2">
            <h2 className="mb-4 text-2xl font-bold font-ebgaramond">Address:</h2>
            <div className="space-y-2">
              <h3 className="text-xl font-bold font-lora">Dr. Baron Tarun Kumar</h3>
              <p className="text-lg font-lora">Hon. Secretary, BEAUIDEAL</p>
              <p className="text-lg font-lora">Professor,</p>
              <p className="text-lg font-lora">Dept. of Periodontology,</p>
              <p className="text-lg font-lora">Bapuji Dental College & Hospital</p>
              <p className="text-lg font-lora">Davangere - 577004</p>
            </div>
          </div>

          {/* Contact Form */}
          <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow-md md:w-1/2">
            <h2 className="mb-4 text-2xl font-bold font-ebgaramond">Get in Touch</h2>
            <form onSubmit={addContact}>
              <div className="mb-4">
                <label htmlFor="name" className="block mb-2 font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={`w-full px-4 py-2 rounded-md bg-gray-100 border ${
                    errors.name ? 'border-red-500' : 'border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                />
                {errors.name && <p className="mt-1 text-sm text-red-500">{errors.name}</p>}
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="block mb-2 font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`w-full px-4 py-2 rounded-md bg-gray-100 border ${
                    errors.email ? 'border-red-500' : 'border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                />
                {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
              </div>

              <div className="mb-6">
                <label htmlFor="message" className="block mb-2 font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows={4}
                  className="w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                ></textarea>
              </div>

              <div className="flex justify-center">
                <button
                  type="submit"
                  className="px-6 py-2 font-medium text-white transition-colors duration-300 bg-blue-600 rounded-md hover:bg-blue-700 font-lora"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlumniContact;
