import pdf from './RTI.pdf';
import React from 'react';
import AHeader from '../components/AHeader';

function Overview() {
  return (
    <div className="min-h-screen">
      <AHeader header={'Right To Information Act 2005'} isScrolled={true} />

      <div className="container px-4 py-8 mx-auto">
        <div className="flex justify-center">
          <div className="w-full p-4 bg-white shadow-lg sm:w-3/4 lg:w-2/3 rounded-xl">
            <iframe
              src={pdf}
              title="rti_pdf"
              className="w-full h-[400px] sm:h-[800px] rounded-lg"
              style={{ border: 'none' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
