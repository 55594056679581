import React from 'react';
import loadingGif from 'assets/BDCH_GIF.gif';

function Loader({ loading }) {
  if (!loading) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#112D58]">
      <div className="relative w-full max-w-md p-4">
        <img
          src={loadingGif}
          alt="Loading..."
          className="w-full h-auto object-contain max-h-[200px]"
        />
      </div>
    </div>
  );
}

export default Loader;
